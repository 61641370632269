import './App.css';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Home from './Pages/Home';
import OurProject from './Pages/OurProject';
import ContactUs from './Pages/ContactUs';
import AboutUs from './Pages/AboutUs';
import Architechure from './Component/Architechure/Architechure';
import InteriorDesigner from './Pages/InteriorDesigner';
import Construction from './Pages/Construction';
import Furniture from './Pages/Furniture';
import Landscaping from './Pages/Landscaping';
import ScrollToTop from './Component/NavBarFooter/ScrollToTop';

function App() {
  return (
<BrowserRouter>
<ScrollToTop />
<Routes>
  <Route path='/' element={<Home/>}/>
  <Route path='/our-project' element={<OurProject/>}/>
  <Route path='/contact-us' element={<ContactUs/>}/>
  <Route path='/about-us' element={<AboutUs/>}/>
  <Route path='/Architechure-servise' element={<Architechure/>}/>
  <Route path='/Interior-Designer-servise' element={<InteriorDesigner/>}/>
  <Route path='/Construction-servise' element={<Construction/>}/>
  <Route path='/Furniture-servise' element={<Furniture/>}/>
  <Route path='/Landscaping-servise' element={<Landscaping/>}/>
</Routes>
</BrowserRouter>
  );
}

export default App;
